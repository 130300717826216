var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loadingState,
          expression: "loadingState",
        },
      ],
      staticClass: "developing",
    },
    [
      _c("div", { staticClass: "zhuan1" }),
      _c("div", { staticClass: "zhuan2" }),
      _c("div", { staticClass: "text" }, [
        _c("div", [_vm._v(_vm._s(_vm.text) + "...")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }